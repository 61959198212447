<script setup>
import Countries from '/utils/countries.json'

// Properties
const props = defineProps({
  error: {
    type: String,
    default: '',
  },
  selected: {
    type: String,
    default: '',
  },
  customField: {
    type: Object,
    default: null
  }
})

// Events
const emit = defineEmits(['custom-blur', 'custom-change', 'on-selection-changed'])

// References
const focused = ref(false)
const nationality = ref('')

// Computed Values
const filteredCountries = computed(() => {
  return Countries.filter(({ name: name }) => {
    return name.common.toLowerCase().includes(nationality.value.toLowerCase())
  })
})

// Handlers
function handleBlur() {
  if (focused.value && nationality.value) {
    return
  }

  // Emit custom event
  emit('custom-blur')
}

function handleFocus() {
  // Change state
  focused.value = true

  // Reset value
  nationality.value = ''

  // Emit custom event
  emit('custom-change', 'nationality', '', false)
}

function handleSelect(country) {
  // Reset state
  focused.value = false

  // Set value
  nationality.value = country

  // Emit custom event
  emit('custom-change', 'nationality', country)
  emit('on-selection-changed', 'nationality', country, props.customField);
}
</script>

<template>
  <input
    type="text"
    :class="['input__search', { 'has-error': error && !nationality }]"
    :placeholder="`${$t('form.fields.nationality')}`"
    @blur="handleBlur"
    @focus="handleFocus"
    v-model="nationality"
  />

  <ul v-show="focused && nationality" class="input-nationality__dropdown">
    <li v-for="country in filteredCountries" :key="country.code">
      <button @click="handleSelect(country.name.common)">
        <img
          :alt="`${country.demonym} flag`"
          :src="`/assets/images/flags/${country.flag.svg}.png`"
          class="input-nationality__flag"
        />
        <span>{{ country.name.common }}</span>
      </button>
    </li>
  </ul>
</template>

<style lang="scss">
@use 'sass:math';

// Utils
@import 'styles/utils/variables';

.input-nationality {
  position: relative;

  &__dropdown {
    background-color: #200929;
    border: solid 1.5px transparent;
    border-radius: 15px;
    margin-top: 6px;
    padding: 10px;
    width: 100%;
    height: 200px;
    position: absolute;
    top: 62px;
    left: 0;
    overflow-y: scroll;
    z-index: 4;
  }

  &__dropdown button {
    background-color: transparent;
    border-radius: 10px;
    padding: 20px;
    display: flex;
    align-items: center;
    width: 100%;
  }

  &__dropdown button:hover {
    background-color: #c80afb;
  }

  // &__flag {
  //   border: solid 1px rgba($brand-white, 0.7);
  //   border-radius: 50%;
  //   display: grid;
  //   justify-items: center;
  //   width: 19px;
  //   height: 19px;
  //   flex-shrink: 0;
  //   overflow: hidden;
  // }

  &__flag {
    // border: solid 1px rgba($brand-white, 0.15);
    border-radius: 50%;
    width: 18px;
    height: 18px;
    flex-shrink: 0;
    overflow: hidden;
  }

  // &__flag svg {
  //   display: block;
  //   width: auto;
  //   height: 100%;
  // }

  &__dropdown span {
    font-size: 12px;
    font-weight: $font-weight-4G;
    line-height: math.div(16, 12);
    letter-spacing: 0.27em;
    text-transform: uppercase;
    color: $brand-white;
    margin-left: 10px;
  }
}
</style>
